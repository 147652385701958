.sliderContainer {
  max-width: calc(100vw - 75px);
  padding-bottom: 71px;

  & :global(.slick-list) {
    max-width: calc(100vw - 90px);
  }

  & :global(.slick-track) {
    display: flex !important;
  }

  & :global(.slick-slide) {
    height: auto !important;

    & > div {
      height: 100%;
    }
  }
}

.sectionHeadingWithExtraMargin {
  font-size: 33px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  /* 100% */
  margin-bottom: 32px;

  @media (max-width: 1023px) {
    margin-bottom: 24px;
    font-size: 24px;
  }
}

.episodesList {
  padding-left: 72px;
  background-color: #000;
  padding-top: 51px;

  @media (max-width: 1023px) {
    padding-left: 22px;
  }
}

.episodeCard {
  background-color: #171819;
  border: 1px solid #171819;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  text-align: left;
  padding: 33px 36px 45px;
  max-width: 431px;
  height: 100%;

  @media (max-width: 767px) {
    padding: 33px 20px 45px;
  }

  & .episodeDetails {
    margin-top: 24px;
  }
}

.episodeThumbnail {
  width: 100%;
  height: auto;
  border-radius: 10px;
  height: 197px;
  display: block;
}

.episodeDetails {
  margin-top: 10px;
}

.episodeTitle {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  /* 125% */
  letter-spacing: -0.348px;
  color: #fff;
  margin: 7px 0;
  padding: 0;
}

.episodeDescription {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0px 0 20px;
  padding: 0;
  word-break: break-word;
}

.episodeDuration {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 157.143% */
  letter-spacing: 0.3px;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
}

.activeEpisode {
  border: 1px solid #ed2124;
}

.videoPlayerContainer {
  position: relative;
  & .closeVideoPlayer {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 3;
    cursor: pointer;
  }
  & .episodeModal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100vh - 160px);
    background-color: #000;
    z-index: 5;
    padding: 46px 46px 0;
    max-width: 929px;

    @media (max-width: 1023px) {
      width: 100%;
      padding: 46px 12px 0;
      max-width: calc(100vw);
    }
  }

  & .episodeModalContent {
    position: relative;
    max-height: calc(100vh - 226px);
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #9d9d9d transparent;
    padding-right: 44px;

    @media (max-width: 1023px) {
      padding-right: 12px;
    }

    & .episodesList {
      padding: 0;
    }
  }

  & .episodeModalContent::-webkit-scrollbar {
    width: 8px;
  }

  & .episodeModalContent::-webkit-scrollbar-thumb {
    background-color: #b3b3b3;
    border-radius: 10px;
  }

  & .episodeModalContent::-webkit-scrollbar-track {
    background: transparent;
  }

  & .closeEpisodeModal {
    position: absolute;
    top: 25px;
    right: 35px;
    z-index: 3;
  }

  & .sliderContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 28px;

    @media (max-width: 1023px) {
      max-width: calc(100vw - 35px);
    }
  }

  & .episodeCard {
    width: calc(50% - 14px);
    min-width: 370px;
    cursor: pointer;
    height: auto !important;

    @media (max-width: 1023px) {
      min-width: auto;
      width: 100%;
    }
  }

  & .commentModalContent {
    & > div {
      padding: 0;
      max-height: calc(100vh - 236px);
      padding-bottom: 20px;
      overflow-y: auto;
      scrollbar-width: thin;
      scrollbar-color: #9d9d9d transparent;
      padding-right: 44px;

      @media (max-width: 1023px) {
        padding-right: 12px;
      }

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #b3b3b3;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      & > div {
        max-width: 100%;
      }

      & > form {
        max-width: 100%;
      }
    }
  }
}

.reportIssueHeading {
  color: var(--White, #fff);
  text-align: center;
  font-family: 'Sofia Pro';
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
  /* 117.857% */
  letter-spacing: 1px;
}

.requestRefundButton {
  max-width: 445px;
  width: 100%;
  height: 56px;
  flex-shrink: 0;
  background: var(--Rabel, #ed2124);
  border-radius: 100px;
}

.requestRefundButton1 {
  max-width: 445px;
  width: 100%;
  height: 56px;
  flex-shrink: 0;
  border-radius: 100px;
  border: 2px solid var(--White, #fff);
  background: var(--Slate, #171819);
}

.contentBox {
  width: 610px;
  height: 384px;
  flex-shrink: 0;
  border-radius: 20px;
  background: var(--Slate, #171819);
  box-shadow: 0px 14px 24px 0px rgba(0, 0, 0, 0.25);
}

.purchaseModal {
  & > div {
    & > div {
      max-width: 610px;

      @media (max-width: 767px) {
        max-width: 100vw;
      }

      & h3 {
        max-width: 432px;
        margin: 0 auto 63px;
      }
    }
  }
}

.buttonGroups {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}
